const isObjectType = (value: unknown) => typeof value === "object"

const isNullOrUndefined = (value: unknown): value is null | undefined => value == null

const isUndefined = (val: unknown): val is undefined => val === undefined

const isDateObject = (value: unknown): value is Date => value instanceof Date

const isEmptyArray = (value: unknown[]): value is [] => typeof value === "undefined" || value?.length === 0

const isString = (value: unknown): value is string => typeof value === "string"

const isObject = <T extends object>(value: unknown): value is T =>
    !isNullOrUndefined(value) && !Array.isArray(value) && isObjectType(value) && !isDateObject(value)

const isEmptyString = (str: string): boolean => {
    if (isNullOrUndefined(str)) {
        return true
    }

    return str.trim().length === 0
}

const isEmptyObject = <T extends object>(value: unknown): value is T =>
    !isNullOrUndefined(value) && isObject(value) && Object.keys(value).length === 0

/**
 * Polyfill for lodash.isEmpty
 * @param value to check
 * @return boolean
 */
function isEmpty(value: unknown): boolean {
    if (value == null) {
        return true
    }

    if (typeof value === "string" || Array.isArray(value)) {
        return value.length === 0
    }

    if (typeof value === "object") {
        return Object.keys(value).length === 0
    }

    if (value instanceof Map || value instanceof Set) {
        return value.size === 0
    }

    return false
}

/**
 * Polyfill for lodash.isEqual. Doesn't provide same deep check though
 * @param value first value to check
 * @param other second value to check
 * @param seen WeakMap for complicated structures
 * @return boolean
 */
function isEqual(value: unknown, other: unknown, seen = new WeakMap()): boolean {
    if (value === other) {
        return true
    }

    if (typeof value !== typeof other) {
        return false
    }

    if (Array.isArray(value) && Array.isArray(other)) {
        if (value.length !== other.length) {
            return false
        }
        for (let i: number = 0; i < value.length; i++) {
            if (!isEqual(value[i], other[i], seen)) {
                return false
            }
        }
        return true
    }

    if (value !== null && typeof value === "object" && other !== null && typeof other === "object") {
        if (seen.has(value)) {
            return seen.get(value) === other
        }
        seen.set(value, other)

        const valueKeys: string[] = Object.keys(value)
        const otherKeys: string[] = Object.keys(other)

        if (valueKeys.length !== otherKeys.length) {
            return false
        }

        for (const key of valueKeys) {
            if (!Object.prototype.hasOwnProperty.call(other, key) || !isEqual(value[key], other[key], seen)) {
                return false
            }
        }
        return true
    }

    return false
}

export {
    isObject,
    isObjectType,
    isNullOrUndefined,
    isUndefined,
    isEmptyString,
    isEmptyObject,
    isDateObject,
    isString,
    isEmptyArray,
    isEmpty,
    isEqual
}
